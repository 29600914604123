import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import React from "react";
import Marquee from "react-fast-marquee";

const data = {
  navigation: [
    { name: "About", href: "/about-me" },
    { name: "Experience", href: "/#recent-works" },
    { name: "Blog", href: "/#blog" },
    { name: "Contact", href: "/about-me/#contact" },
  ],

  social: [
    {
      name: "Github",
      url: "https://github.com/jeanc18rlos",
    },
    {
      name: "Codepen",
      url: "https://codepen.io/jeanc18rlos",
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/jeanrojasdev/",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/rojsdev",
    },
    {
      name: "Behance",
      url: "https://www.behance.net/jeanrojasdev",
    },
    {
      name: "Quora",
      url: "https://www.quora.com/profile/Jean-Rojas-4",
    },
    { name: "AdpList", url: "https://adplist.org/mentors/jean-rojas" },
  ],

  marquee: [
    { name: "CASE STUDIES", href: "/#recent-works" },
    { name: "CONTACT ME", href: "/about-me/#contact" },
    { name: "CASE STUDIES", href: "/#recent-works" },
    { name: "CONTACT ME", href: "/about-me/#contact" },
    { name: "CASE STUDIES", href: "/#recent-works" },
    { name: "CONTACT ME", href: "/about-me/#contact" },
    { name: "CASE STUDIES", href: "/#recent-works" },
    { name: "CONTACT ME", href: "/about-me/#contact" },
  ],
};

export default function Example() {
  return (
    <footer
      className="bg-cod-gray border-t border-stone-700"
      aria-labelledby="footer-heading"
    >
      <Marquee pauseOnHover={true} className="py-4 border-b border-stone-700">
        {data.marquee.map((link, linkIdx) => {
          return (
            <AnchorLink
              key={linkIdx}
              className={`font-bold text-7xl  mx-4 ${
                linkIdx % 2 === 0
                  ? "text-white hover:text-stone-500"
                  : "text-stone-700 hover:text-white"
              }`}
              to={link.href}
            >
              {link.name}
            </AnchorLink>
          );
        })}
      </Marquee>
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="font-bold text-3xl text-white">
                jeanrojas.com
              </span>
            </Link>
            <p className="text-base leading-6 text-stone-500">
              Boosting remote teamwork and improving systems architecture
              focusing on team communication patterns.
            </p>
            <br />
            <br />
            <a
              className="text-xl font-bold  leading-6 text-stone-500 hover:text-white"
              href="mailto:jrojastechnology@gmail.com"
            >
              jrojastechnology@gmail.com
            </a>
            <br />
            <a
              href="tel:+19292245443"
              className="text-xl font-bold leading-6 text-stone-500 hover:text-white"
            >
              +1 (929) 2245443
            </a>
          </div>
          <div className="mt-16 grid grid-cols-2 lg:grid-cols-3 gap-8 xl:col-span-2 xl:mt-0">
            <div>
              <h3 className="text-lg font-semibold leading-6 text-white">
                Links
              </h3>
              <ul role="list" className="mt-6 space-y-4">
                {data.navigation.map((item) => (
                  <li key={item.name}>
                    <AnchorLink
                      to={item.href}
                      className="text-base leading-6 text-stone-500 hover:text-white"
                    >
                      {item.name}
                    </AnchorLink>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold leading-6 text-white">
                Social
              </h3>
              <ul role="list" className="mt-6 space-y-4">
                {data.social.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-base leading-6 text-stone-500 hover:text-white"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-span-2 order-1 lg:order-last lg:col-span-1">
              <h3 className="text-base font-semibold leading-6 text-white">
                Subscribe to my newsletter
              </h3>
              <p className="mt-2 text-base leading-6 text-gray-300">
                The latest news, articles, and resources, sent to your inbox
                weekly.
              </p>
              <form
                className="mt-6 flex flex-col max-w-md"
                action="https://squidred.us12.list-manage.com/subscribe/post"
                method="POST"
              >
                <input
                  type="hidden"
                  name="u"
                  value="eba1fb53bb25d81196aaa677c"
                />
                <input type="hidden" name="id" value="a01618e6d6" />
                <label htmlFor="MERGE0" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="MERGE0"
                  id="MERGE0"
                  autoComplete="email"
                  required
                  className="w-full min-w-0 appearance-none rounded-md border-white/10 bg-gray-400/10 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base leading-7 text-white placeholder-gray-500 shadow-sm sm:w-64 sm:text-base sm:leading-6 xl:w-full"
                  placeholder="Enter your email"
                />
                <div className="mt-4 rounded-md sm:flex-shrink-0">
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md py-2 text-base font-semibold leading-7 bg-white text-black hover:bg-black hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white sm:text-base sm:leading-6"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-stone-500">
            &copy; Jeanrojas.com All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
