import { Link } from "gatsby";
import React from "react";
import { MenuButton } from "./burgerMenu";
import { AnimatePresence, motion } from "framer-motion";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import "./header.css";
const dropdownVariants = {
  open: { maxHeight: `calc(100vh - 4rem)`, opacity: 1 },
  closed: { maxHeight: 0, opacity: 0 },
};

const navigation = [
  { name: "About", href: "/about-me" },
  { name: "Experience", href: "/#recent-works" },
  { name: "Blog", href: "/#blog" },
  { name: "Contact", href: "/about-me/#contact" },
];

const social = [
  {
    name: "Github",
    url: "https://github.com/jeanc18rlos",
  },
  {
    name: "Codepen",
    url: "https://codepen.io/jeanc18rlos",
  },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/in/jeanrojasdev/",
  },
  {
    name: "Twitter",
    url: "https://twitter.com/rojsdev",
  },
  {
    name: "Behance",
    url: "https://www.behance.net/jeanrojasdev",
  },
  {
    name: "Quora",
    url: "https://www.quora.com/profile/Jean-Rojas-4",
  },
  { name: "AdpList", url: "https://adplist.org/mentors/jean-rojas" },
];

export default () => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <header className="header">
      <nav className="header__nav" aria-label="Global">
        <span className="header__branding">
          <Link to="/">jeanrojas.com</Link>
          <button
            onClick={() => setOpen(!isOpen)}
            className="header__menu-toggle"
          >
            <MenuButton isOpen={isOpen} />
          </button>
        </span>
      </nav>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={dropdownVariants}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="header__dropdown"
          >
            <main className="header__dropdown-content">
              <ul className="header__social-links">
                {social.map((item) => (
                  <li key={item.name}>
                    <a href={item.url} rel="noopener noreferrer" target="_blank">{item.name}</a>
                  </li>
                ))}
              </ul>
              <ul className="header__navigation-links">
                {navigation.map((item) => (
                  <li
                    key={item.name}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <AnchorLink to={item.href}>{item.name}</AnchorLink>
                  </li>
                ))}
              </ul>
            </main>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};
