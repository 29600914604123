import React from "react";
import Header from "./header";
import Footer from "./footer";

export const Container: React.FC<{
  children: any;
}> = ({ children }) => {
  return <div className=" px-2 xs:px-4">{children}</div>;
};

const Layout: React.FC<{
  children: any;
}> = ({ children }) => {
  return (
    <div className="isolate bg-cod-gray">
      <Header />

      <main className="max-w-7xl px-4 xs:px-4 lg:px-12 xl:px-4 m-auto mt-20">
        <div className="border-x border-stone-700">
          <br />
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export const wrapper: React.FC<{
  element: any;
}> = ({ element }) => {
  return <Layout>{element}</Layout>;
};

export default Layout;
